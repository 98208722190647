/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import Header from '../../layout/header';
import SideBar from '../../layout/sideBar';
import Footer from '../../layout/footer';

const ThemeContainer = ({ children }) => {
  const location = useLocation();
  const { startKYC } = useSelector((state) => state.kycReducer);

  const page = location?.pathname;

  return (
    <div className="">
      <Header />
      {
        page && (page === '/' || page === "/dashboard") ?
          <div className="page-content bg-style no-sidebar w-100 float-start position-relative pb-lg-0 pb-5" style={{ minHeight: "100vh" }}>
            <div className='content'>
              {children}
            </div>
            <Footer />
          </div>
          :
          <div className="page-content w-100 float-start position-relative">
            <SideBar />
            <div className="content-wrapper" style={startKYC ? { marginLeft: "0px", marginTop: "5rem" } : { marginTop: "5rem" }}>
              <div className='card border-0 m-4 m-lg-5'>
                {children}
              </div>
              <Footer />
            </div>
          </div>
      }
    </div>
  )
}

export default ThemeContainer;