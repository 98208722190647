/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fragment, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { historyResponseSet } from "../../utils/token";
import { getNatureList, getWithdrawalDetails } from "./services/natureService";
import { getDividendList } from "../dividend/services/dividendService";
import Pagination from "../../../components/layout/pagination";
import { PROJECT_TYPES } from "../../constants/project-types";
import { USER_ID_TYPES, ACCESS_TOKEN_TYPES } from "../../constants/localstorage-types";
import { setNatureDividend } from "../dividend/redux/dividendAction";

const Nature = () => {
  const dispatch = useDispatch();
  const { userDetail } = useSelector((state) => state.profileReducer);
  const { nature_dividend } = useSelector((state) => state.dividendReducer);

  const [investmentHistory, setInvestmentHistory] = useState([]);
  const [paginateData, setPaginateData] = useState([]);
  const [dividend, setDividend] = useState(0);
  const [withdrawalAmount, setWithdrawalAmount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(false);

  const getNatureHistoryList = async () => {
    setIsLoading(true);
    const data = {
      user_id: userDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]],
      access_token: userDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]],
    }
    const res = await getNatureList(data);
    setInvestmentHistory(historyResponseSet(res?.investment));

    const dividendHistory = await getDividendList(data, PROJECT_TYPES.PROJECT_TYPES_NATURE);
    if (dividendHistory?.total_dividend_amount) {
      setDividend(dividendHistory.total_dividend_amount);
      dispatch(setNatureDividend({ ...nature_dividend, dividend: dividendHistory.total_dividend_amount }));
    }

    setIsLoading(false);
  }

  useEffect(() => {
    getNatureHistoryList();
    getWithdrawalDetail();
  }, []);

  useEffect(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    setPaginateData(investmentHistory.slice(firstPageIndex, lastPageIndex));
  }, [investmentHistory, currentPage, pageSize]);

  const handleInvestmentBtn = async () => {
    const data = {
      user_id: userDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]],
      access_token: userDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]],
    }
    window.open(`${process.env.REACT_APP_NATURE_INVESTMENT_URL}?user_id=${data.user_id}&access_token=${data.access_token}`, "_blank", "noreferrer");
  }

  const getWithdrawalDetail = async () => {
    const data = {
      user_id: userDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_WALLET]],
      access_token: userDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_WALLET]],
    };
    const res = await getWithdrawalDetails(data);
    if (res?.status) {
      setWithdrawalAmount(res.withdraw_amount);
    } else {
      setWithdrawalAmount(0);
    }
  }

  return (
    <Fragment>
      <div className="page-title mb-4 mb-lg-4 px-3 px-lg-5">
        <h4 className="mb-0">Nature Project</h4>
        <button className="btn btn-secondary" onClick={() => handleInvestmentBtn()}>Invest Now</button>
      </div>
      <div className="content px-3 px-lg-5">
        <div className="table-box">
          <div className="table-responsive">
            <table className="table table-bordered w-100 float-start">
              <thead>
                <tr>
                  {/* <th className="sorting">Projects</th>
                  <th className="sorting sorting_asc">Token purchase</th>
                  <th className="sorting sorting_desc">Invested Amount(USD)</th> */}
                  <th className="">Projects</th>
                  <th className="">Token purchase</th>
                  <th className="">Invested Amount(USD)</th>
                </tr>
              </thead>
              <tbody>
                {

                  !isLoading && paginateData?.length > 0 ?
                    paginateData.map((val, index) => {
                      return (
                        <tr key={index}>
                          <td>{val?.project}</td>
                          <td>{val?.purchased_token}</td>
                          <td>${val?.invested_amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 25 })}</td>
                        </tr>
                      )
                    })
                    :
                    (
                      isLoading ?
                        <tr>
                          <td className="text-center" colSpan={3}>
                            <div className="spinner-border" role="status">
                              <span className="visually-hidden">Loading...</span>
                            </div>
                          </td>
                        </tr>
                        :
                        <tr>
                          <td className="text-center" colSpan={3}>No Data Found</td>
                        </tr>
                    )
                }
              </tbody>
            </table>
          </div>
        </div>

        <Pagination currentPage={currentPage} totalCount={investmentHistory.length} pageSize={pageSize} onPageChange={(page) => setCurrentPage(page)} setPageSize={setPageSize} />

        <div className="row">
          <div className="col-12">
            <div className="btn-group-style">
              <NavLink to="/dividend" className="btn btn-primary">Dividend Amount: ${dividend.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 25 })}</NavLink>
              <NavLink to="/withdraw" className="btn btn-primary">Withdrawable Amount: ${withdrawalAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 25 })}</NavLink>
            </div>
          </div>
        </div>
      </div>
    </Fragment >
  )
}

export default Nature;