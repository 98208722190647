/* eslint-disable multiline-comment-style */
import api_end_point from "../../../API/api-end-point";
import apiServices from "../../../API/api_service";

const getKYCAccessToken = async (data) => {
  const url = api_end_point.SUMSUB_ACCESS_TOKEN;
  const result = await apiServices.post(url, data);
  return result;
};

const getUserKYCStatus = async (data) => {
  const url = api_end_point.KYC_STATUS;
  const result = await apiServices.post(url, data);
  return result;
};

export { getKYCAccessToken, getUserKYCStatus };
