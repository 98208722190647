/* eslint-disable @typescript-eslint/no-explicit-any */
import { usePagination, DOTS } from "./usePagination";

const Pagination = ({ currentPage, totalCount, pageSize, onPageChange, setPageSize, siblingCount = 1 }) => {
  const paginationRange = usePagination({ currentPage, totalCount, siblingCount, pageSize });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const handleEntries = (e) => {
    setPageSize(parseInt(e.target.value));
  }

  const lastPage = paginationRange[paginationRange.length - 1];

  return (
    <div className="pagination-section pt-3">
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="entries-box">
            <div className="entries-box-info">
              <label className="d-flex align-items-center gap-2">Show
                <select name="example_length" aria-controls="example" className="w-auto form-select form-select-sm" onChange={(e) => handleEntries(e)}>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select> entries
              </label>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="pagination-box">
            <ul className="pagination float-md-end">
              {
                currentPage !== 1 &&
                <li className="paginate_button page-item previous" onClick={() => onPrevious()} >
                  <span className="page-link arrow text-center">
                    <i className="fas fa-chevron-left"></i>
                    <i className="fas fa-chevron-left"></i>
                  </span>
                </li>
              }

              {paginationRange.map((pageNumber) => {
                if (pageNumber === DOTS) {
                  return <li className="pagination-item dots" key={pageNumber}>&#8230;</li>;
                }

                return (
                  <li className="paginate_button page-item" key={pageNumber}>
                    <span className={currentPage === pageNumber ? "page-link active" : "page-link"} onClick={() => onPageChange(pageNumber)} >{pageNumber}</span>
                  </li>
                )
              })}

              {
                currentPage !== lastPage &&
                <li className="paginate_button page-item next" onClick={() => onNext()}>
                  <span className="page-link arrow">
                    <i className="fas fa-chevron-right"></i>
                    <i className="fas fa-chevron-right"></i>
                  </span>
                </li>
              }
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
