/* eslint-disable @typescript-eslint/no-explicit-any */
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { encryptTransform } from "redux-persist-transform-encrypt";
import { profileConstant } from "./profileAction";

const initialState = {
  isLoggedIn: false,
  userDetail: {}
}

const persistConfig = {
  storage,
  key: "sunset-wallet-profile",
  whitelist: ["isLoggedIn", "userDetail"],
  transforms: [
    encryptTransform({
      secretKey: 'sunset-wallet-persist-secure',
      onError: function (error) {
        // Handle the error.
      }
    })
  ]
};

const profileReducer = persistReducer(persistConfig, (state = initialState, action) => {
  switch (action.type) {
    case profileConstant.SET_IS_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    case profileConstant.SET_USER_DETAIL:
      return {
        ...state,
        userDetail: action.payload,
      };

    default:
      return state;
  }
});

export default profileReducer
