/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/anchor-is-valid */
import "../../../assets/css/sidebar.css";
import { NavLink } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import solarEnergyIcon from "../../../assets/icon/solar-energy.svg";
import planetEarthIcon from "../../../assets/icon/planet-earth.svg";
import dataCenterIcon from "../../../assets/icon/data-center.svg";
import allocationIcon from "../../../assets/icon/allocation.svg";
import withdrawalIcon from "../../../assets/icon/withdrawal.svg"
import { PROJECT_DISABLE_STATUS } from "../../../app/constants/constants";

const SideBar = ({ closeNav }) => {
  const location = useLocation();
  const page = location?.pathname;
  const splitUrl = page.split("/");

  return (
    <div className="sidebar-content bg-white">
      <ul className="nav-style d-flex flex-column list-unstyled mb-0">
        {
          !PROJECT_DISABLE_STATUS?.ENERGY &&
          <li className={page === "/energy" ? "active" : ""}>
            <NavLink to="/energy" onClick={closeNav}>
              <div className="menu-icon-box">
                <img src={solarEnergyIcon} alt="" />
              </div>
              <span>Energy</span>
            </NavLink>
          </li>
        }

        {
          !PROJECT_DISABLE_STATUS?.NATURE &&
          <li className={page === "/nature" ? "active" : ""}>
            <NavLink to="/nature" onClick={closeNav}>
              <div className="menu-icon-box">
                <img src={planetEarthIcon} alt="" />
              </div>
              <span>Nature</span>
            </NavLink>
          </li>
        }

        {
          !PROJECT_DISABLE_STATUS?.GREENH2 &&
          <li className={page === "/greenH2" ? "active" : ""}>
            <NavLink to="/greenH2" onClick={closeNav}>
              <div className="menu-icon-box">
                <img src={solarEnergyIcon} alt="" />
              </div>
              <span>Green H2</span>
            </NavLink>
          </li>
        }

        {
          !PROJECT_DISABLE_STATUS?.BIOCHAR &&
          <li className={page === "/biochar" ? "active" : ""}>
            <NavLink to="/biochar" onClick={closeNav}>
              <div className="menu-icon-box">
                <img src={solarEnergyIcon} alt="" />
              </div>
              <span>Biochar</span>
            </NavLink>
          </li>
        }

        {
          !PROJECT_DISABLE_STATUS?.GREENDATACENTER &&
          <li className={page === "/greenDataCenter" ? "active" : ""}>
            <NavLink to="/greenDataCenter" onClick={closeNav}>
              <div className="menu-icon-box">
                <img src={dataCenterIcon} alt="" />
              </div>
              <span>Green Data Center</span>
            </NavLink>
          </li>
        }
        <li className={page === "/dividend" || splitUrl[1] === "dividend" ? "active" : ""}>
          <NavLink to="/dividend" onClick={closeNav}>
            <div className="menu-icon-box">
              <img src={allocationIcon} alt="" />
            </div>
            <span>Dividend</span>
          </NavLink>
        </li>
        <li className={page === "/withdraw" ? "active" : ""}>
          <NavLink to="/withdraw" onClick={closeNav}>
            <div className="menu-icon-box">
              <img src={withdrawalIcon} alt="" />
            </div>
            <span>Withdraw</span>
          </NavLink>
        </li>
      </ul>
    </div >
  );
};

export default SideBar;
