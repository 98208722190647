/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable multiline-comment-style */
import api_end_point from "../../../API/api-end-point";
import apiServices from "../../../API/api_service";

const getDividendList = async (data, pageType) => {
  const url = api_end_point.DIVIDEND_HISTORY;
  const result = await apiServices.post(url, data, pageType);
  return result;
};

const getMasterData = async (data, pageType) => {
  const url = api_end_point.ASSET_MASTER_DATA;
  const result = await apiServices.post(url, data, pageType);
  return result;
};

const getEstimatedDividend = async (data, pageType) => {
  const url = api_end_point.ESTIMATED_DIVIDEND;
  const result = await apiServices.post(url, data, pageType);
  return result;
};

const btcToUsd = async () => {
  // const url = "https://api.kucoin.com/api/v1/prices?currencies=BTC";
  const url = `${api_end_point.CRYPTO_PRICE}/BTC`;
  const result = await apiServices.get(url);
  return result;
};

export {
  getDividendList,
  getMasterData,
  getEstimatedDividend,
  btcToUsd,
}