import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import EstimatedDividendList from './estimatedDividendList';
import DividendList from './dividendList';
import { PROJECT_TYPES } from '../../../constants/project-types';

const DividendTab = () => {

  const { pageType } = useParams();

  const tab = {
    estimatedDividend: "estimated_dividend",
    dividend: "dividend"
  }
  const [currentTab, setCurrentTab] = useState(tab.estimatedDividend);

  return (
    <div>
      {
        pageType === PROJECT_TYPES.PROJECT_TYPES_GREEN_DATA_CENTER ?
          <>
            <ul className="nav nav-tabs mt-3">
              <li className="nav-item">
                <div className={currentTab === tab.estimatedDividend ? "nav-link active" : "nav-link pointer"} aria-current="page" onClick={() => setCurrentTab(tab.estimatedDividend)}>Estimated Dividend</div>
              </li>
              <li className="nav-item">
                <div className={currentTab === tab.dividend ? "nav-link active" : "nav-link pointer"} onClick={() => setCurrentTab(tab.dividend)}> Dividend</div>
              </li>
            </ul>

            {currentTab === tab.estimatedDividend && <EstimatedDividendList />}
            {currentTab === tab.dividend && <DividendList />}
          </>
          :
          <DividendList />
      }

    </div >
  )
}

export default DividendTab