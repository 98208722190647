/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fragment, useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { withdrawRequest, getWithdrawalDetails } from './services/withdrawService';
import useAlert from '../../hooks/use-alert';
import { USER_ID_TYPES, ACCESS_TOKEN_TYPES } from '../../constants/localstorage-types';
import { PROJECT_TYPES } from '../../constants/project-types';

const Withdraw = () => {
  const { showAlert } = useAlert();
  const { userDetail } = useSelector((state) => state.profileReducer);

  const [withdrawMode, setWithdrawMode] = useState("bank_transfer");
  const [withdrawAmount, setWithdrawAmount] = useState(0);
  const [withdrawAmountErr, setWithdrawAmountErr] = useState(null);
  const [bankDetail, setBankDetail] = useState(null);
  const [walletAddress, setWalletAddress] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [withdrawalAmount, setWithdrawalAmount] = useState(0);

  const userName = userDetail?.user_name;

  const handleWithdrawAmount = (e) => {
    const withdrawAmount = e.target.value;
    if (!withdrawAmount || parseFloat(withdrawAmount) === 0) {
      setWithdrawAmountErr("Please enter withdraw amount");
    } else if (parseFloat(withdrawAmount) > parseFloat(withdrawalAmount)) {
      setWithdrawAmountErr(`Your withdrawal amount is ${withdrawalAmount}`);
    } else {
      setWithdrawAmountErr(null);
    }
    setWithdrawAmount(withdrawAmount)
  }

  const handleWithdrawRequest = async (e) => {
    e.preventDefault();
    if (parseFloat(withdrawAmount) < parseFloat("50")) {
      setIsLoading(false);
      showAlert("ERROR", "ERROR", "Minimum balance of $50 is necessary to initiate a withdrawal.");
    } else {
      if (!withdrawAmountErr) {
        setIsLoading(true);
        const reqData = {
          user_id: userDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_WALLET]],
          access_token: userDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_WALLET]],
          amount: parseFloat(withdrawAmount),
          payment_mode: withdrawMode
        }
        const res = await withdrawRequest(reqData);

        if (res.status) {
          showAlert("SUCCESS", "Success", res.message);
          setWithdrawAmount(0);
          getWithdrawalDetail();
        } else {
          showAlert("ERROR", "ERROR", res.message);
        }
        setIsLoading(false);
      }
    }
  }

  useEffect(() => {
    setBankDetail(userDetail?.user_bank_details);
    setWalletAddress(userDetail?.wallet_address);
    getWithdrawalDetail();
  }, []);

  const getWithdrawalDetail = async () => {
    const data = {
      user_id: userDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_WALLET]],
      access_token: userDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_WALLET]],
    };
    const res = await getWithdrawalDetails(data);
    if (res?.status) {
      setWithdrawalAmount(res.withdraw_amount);
    } else {
      setWithdrawalAmount(0);
    }
  }

  return (
    <Fragment>
      <div className="page-title mb-4 mb-lg-4 px-3 px-lg-5">
        <h4 className="mb-0">Withdraw</h4>
      </div>
      <div className="content px-3 px-lg-5">
        <div className="withdraw-details-box bg-white pt-lg-4">
          <form className="w-100" onSubmit={handleWithdrawRequest}>
            <div className="row mb-4 mb-lg-5">
              <div className="col-12">
                <div className="switches">
                  <h3>Select Withdraw Mode:</h3>
                  <div className="switches-container">
                    <input type="radio" id="switchMonthly" name="switchPlan" value="Bank Transfer" checked={withdrawMode === "bank_transfer"} onChange={() => setWithdrawMode("bank_transfer")} />
                    <input type="radio" id="switchYearly" name="switchPlan" value="Crypto" checked={withdrawMode === "usdt"} onChange={() => setWithdrawMode("usdt")} />
                    <label htmlFor="switchMonthly">Bank Transfer</label>
                    <label htmlFor="switchYearly">Crypto</label>
                    <div className="switch-wrapper">
                      <div className="switch">
                        <div>Bank Transfer</div>
                        <div>Crypto</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row flex-column-reverse flex-lg-row">
                <div className="col-12 col-lg-6 pe-lg-5">
                  <div className="row mb-4">
                    <div className="col-12">
                      <label htmlFor="basic-url" className="form-label">Available Balance:</label>
                      <div className="input-group mb-3">
                        <span className="input-group-text">$</span>
                        <input type="text" className="form-control" value={withdrawalAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 25 })} aria-label="Amount (to the nearest dollar)" disabled />
                      </div>
                    </div>
                    <div className="col-12">
                      <label htmlFor="basic-url" className="form-label">Enter Withdraw Amount:</label>
                      <div className="input-group mb-3">
                        <span className="input-group-text">$</span>
                        <input
                          type="number"
                          className="form-control no-spinner"
                          value={withdrawAmount}
                          aria-label="Amount (to the nearest dollar)"
                          onChange={(e) => handleWithdrawAmount(e)}
                          onKeyDown={(evt) => (evt.key === 'e' || evt.key === '-') && evt.preventDefault()}
                        />
                      </div>
                      {withdrawAmountErr &&
                        <p className="text-danger">
                          <small>{withdrawAmountErr}</small>
                        </p>
                      }
                    </div>
                    <div className="col-12">
                      <label htmlFor="basic-url" className="form-label">Remaining Balance:</label>
                      <div className="input-group mb-3">
                        <span className="input-group-text">$</span>
                        <input
                          type="text"
                          className="form-control"
                          value={parseFloat((withdrawalAmount) - parseFloat(withdrawAmount ? withdrawAmount : 0)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 25 })}
                          aria-label="Amount (to the nearest dollar)"
                          disabled
                        />
                      </div>
                    </div>
                    {
                      isLoading ?
                        <div className="col-12 mt-4">
                          <button type="submit" className="btn" disabled>
                            <div className="spinner-border" role="status">
                              <span className="visually-hidden">Loading...</span>
                            </div>
                          </button>
                        </div>
                        :
                        withdrawMode === "bank_transfer" && bankDetail?.account_number ?
                          <div className="col-12 mt-4">
                            <button type="submit" className="btn btn-submit" disabled={withdrawAmountErr}>Submit</button>
                          </div>
                          : withdrawMode === "usdt" && walletAddress &&
                          <div className="col-12 mt-4">
                            <button type="submit" className="btn btn-submit" disabled={withdrawAmountErr}>Submit</button>
                          </div>
                    }
                    {withdrawMode === "bank_transfer" && !bankDetail?.account_number && <p style={{ color: "red" }}>Please provide your bank account details in the wallet menu to initiate a withdrawal request.</p>}
                    {withdrawMode === "usdt" && !walletAddress && <p style={{ color: "red" }}>Please Connect wallet in the wallet menu to initiate a withdrawal request.</p>}
                  </div>
                </div>
                <div className="col-12 col-lg-6 mb-lg-0 mb-4 mt-3">
                  <div className="withdraw-details rounded mb-0">
                    {
                      withdrawMode === "bank_transfer" ?
                        <ul className="list-unstyled">
                          <li><h4>Bank Account Details</h4></li>
                          <li><strong>Bank Name:</strong> {bankDetail?.bank_name ? bankDetail.bank_name : "XXXX"}</li>
                          <li><strong>Account Holder Name:</strong> {bankDetail?.account_number && userName ? userName : "XXX"}</li>
                          <li><strong>Account Number:</strong> {bankDetail?.account_number ? bankDetail.account_number : "XXXX"}</li>
                          <li><strong>Swift Code:</strong>{bankDetail?.swift_code ? bankDetail.swift_code : "XXXX"}</li>
                        </ul>
                        :
                        <ul className="list-unstyled">
                          <li><strong>Wallet Address:</strong> {walletAddress ? walletAddress : "XXXX"}</li>
                        </ul>
                    }
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  )
}

export default Withdraw