/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { getQueryParams } from "../utils/token";
// import { checkUserKYCStatus } from "../pages/kyc-verification/redux/kycAction";
// import { getUserKYCStatus } from "../pages/kyc-verification/services/kycServices";
import { setUserLoginStatus, setUserDetail } from "../pages/profile/redux/profileAction";
import { checkLogin } from "../API/services/checkLogin";
import SubsubKYC from "../pages/kyc-verification/components/subsum-kyc";
import { setStartKYC } from "../pages/kyc-verification/redux/kycAction";

const PrivateRoute = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  // const { startKYC, isUserKYCDone } = useSelector((state) => state.kycReducer);
  const { startKYC } = useSelector((state) => state.kycReducer);
  const { userDetail } = useSelector((state) => state.profileReducer);

  const [isAuthenticate, setIsAuthenticate] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const loginURL = process.env.REACT_APP_LOGIN_URL;

  useEffect(() => {
    if (location.pathname !== "/kyc-verification") {
      dispatch(setStartKYC(false))
    }
  }, [location.pathname]);

  const fetchData = async () => {
    try {
      const userId = userDetail?.user_id;
      const accessToken = userDetail?.access_token;
      const routeData = getQueryParams();

      if (routeData?.user_id && routeData?.access_token) {
        const checkWithRoute = await checkIsLogin(routeData?.user_id, routeData?.access_token);
        if (!checkWithRoute) {
          const checkWithStorage = await checkIsLogin(parseInt(userId), accessToken);
          if (!checkWithStorage) {
            window.location.href = loginURL;
          }
        }
      } else if (userId && accessToken) {
        const checkWithStorage = await checkIsLogin(parseInt(userId), accessToken);
        if (!checkWithStorage) {
          window.location.href = loginURL;
        }
      } else {
        setIsAuthenticate(false);
        dispatch(setUserDetail({}));
        dispatch(setUserLoginStatus(false));
        setIsLoading(false);
        window.location.href = loginURL;
      }
    } catch (error) {
      console.log("Private Route: ", error);
    }
  }

  const checkIsLogin = async (userId, accessToken) => {
    const result = await checkLogin({ user_id: parseInt(userId), access_token: accessToken });
    if (result?.status) {
      dispatch(setUserDetail(result?.loginUserDetail));
      // !isUserKYCDone && dispatch(checkUserKYCStatus(getUserKYCStatus));
      dispatch(setUserLoginStatus(true));
      setIsAuthenticate(true);
      setIsLoading(false);
      if (!result?.loginUserDetail?.kyc_done && location.pathname !== "/") {
        navigate("/kyc-verification");
      }

      if (result?.loginUserDetail?.isAdmin) {
        window.location.replace(`${process.env.REACT_APP_ADMIN_NATURE_URL}?user_id=${userId}&access_token=${accessToken}`);
      }

      return true;
    } else {
      dispatch(setUserDetail(result?.loginUserDetail));
      return false;
    }
  }

  useEffect(() => {
    fetchData();
    if (userDetail?.isAdmin) {
      window.location.replace(process.env.REACT_APP_ADMIN_NATURE_URL);
    }
  }, [location?.pathname]);

  if (isLoading) {
    return (
      <div className="Loader2">
        <Spinner animation="border" />
      </div>
    );
  }

  if (startKYC) return <SubsubKYC />;

  return isAuthenticate ? <Outlet /> : <></>;
}

export default PrivateRoute;