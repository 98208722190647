/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import sunsetLogo from "../../../assets/images/main-logo.png";
import friendIcon from "../../../assets/icon/friends.svg";
import { logout } from "../../../app/pages/profile/services/profileService";
import MobileSideBar from "./mobile-side-bar";
import { USER_ID_TYPES, ACCESS_TOKEN_TYPES } from "../../../app/constants/localstorage-types";
import { PROJECT_TYPES } from "../../../app/constants/project-types";
import { setUserDetail } from "../../../app/pages/profile/redux/profileAction";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { userDetail } = useSelector((state) => state.profileReducer);

  const [isOpenSideBar, setIsOpenSideBar] = useState(false);

  const handleLogout = async () => {
    const data = {
      user_id: userDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]],
      access_token: userDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]],
    };
    const res = await logout(data);
    if (res?.success) {
      dispatch(setUserDetail({}));
      window.location.href = process.env.REACT_APP_LOGIN_URL;
    }
  };

  const openNav = () => {
    setIsOpenSideBar(true);
  };

  const closeNav = () => {
    setIsOpenSideBar(false);
  };

  return (
    <div className="header-main ">
      <nav className="navbar navshadow-style bg-white sticky-top navbar-expand-lg px-lg-4 px-md-1">
        <div className="container-fluid">

          {
            location.pathname === "/" || location.pathname === "/dashboard" || !userDetail?.kyc_done ? <></> :
              <button
                className="navbar-toggler ms-3"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={openNav}
              >
                <span className="navbar-toggler-icon"></span>
              </button>
          }

          <NavLink className="navbar-brand" to="/">
            <img src={sunsetLogo} className="img-fluid" alt="" />
          </NavLink>

          <button className="navbar-toggler d-none" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item dropdown">
                <span className="nav-link dropdown-toggle btn-success-color" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <img src={friendIcon} className="me-lg-2 img-fluid" alt="" />
                  <span>{userDetail?.user_name}</span>
                </span>
                <ul className="dropdown-menu">
                  {
                    userDetail?.kyc_done && location.pathname !== "/" ?
                      <li>
                        <span className="dropdown-item" onClick={() => navigate("/")}>Portfolio</span>
                      </li> :
                      <></>
                  }
                  {
                    userDetail?.kyc_done && location.pathname !== "/profile" &&
                    <li>
                      <span className="dropdown-item" onClick={() => navigate("/profile")}>Profile</span>
                    </li>
                  }
                  {
                    (userDetail?.kyc_done || location.pathname === "/") && location.pathname !== "/kyc-verification" &&
                    <li>
                      <span className="dropdown-item" onClick={() => navigate("/kyc-verification")}>KYC</span>
                    </li>
                  }
                  <li>
                    <span className="dropdown-item" onClick={() => handleLogout()}>Logout</span>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <ul className="navbar-nav d-sm-inline-block d-lg-none ms-lg-auto">
            <li className="nav-item dropdown">
              <span className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <img src={friendIcon} className="me-lg-2" alt="" />
                <span>{userDetail?.user_name}</span>
              </span>
              <ul className="dropdown-menu">
                <li className="d-flex" style={{ backgroundColor: "#186300" }}>
                  <img src={friendIcon} className="ms-2" width={20} alt="" />{" "}
                  <small className="dropdown-item font-16" style={{ color: "#fff" }}>{userDetail?.user_name}</small>
                </li>
                {
                  userDetail?.kyc_done && location.pathname !== "/" ?
                    <li>
                      <span className="dropdown-item" onClick={() => navigate("/")}>Portfolio</span>
                    </li> :
                    <></>
                }
                {
                  userDetail?.kyc_done && location.pathname !== "/profile" &&
                  <li>
                    <span className="dropdown-item" onClick={() => navigate("/profile")}>Profile</span>
                  </li>
                }
                {
                  (userDetail?.kyc_done || location.pathname === "/") && location.pathname !== "/kyc-verification" &&
                  <li>
                    <span className="dropdown-item" onClick={() => navigate("/kyc-verification")}>KYC</span>
                  </li>
                }
                <li>
                  <span className="dropdown-item" onClick={() => handleLogout()}>Logout</span>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
      {
        userDetail?.kyc_done &&
        <div id="mySidenav" className="sidenav" style={isOpenSideBar ? { width: "250px" } : { width: "0px" }}>
          <div className="closebtn" onClick={closeNav}>
            &times;
          </div>
          <MobileSideBar closeNav={closeNav} />
        </div>
      }
    </div >
  )
}

export default Header