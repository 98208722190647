/* eslint-disable @typescript-eslint/no-explicit-any */

export const kycConstant = {
  SET_START_KYC: "SET_START_KYC",
  SET_SUMSUB_ACCESS_TOKEN: "SET_SUMSUB_ACCESS_TOKEN",
  SET_KYC_STATUS: "SET_KYC_STATUS"
};

const setStartKYC = (status) => {
  return {
    type: kycConstant.SET_START_KYC,
    payload: {
      status,
    },
  };
};

const setSumSubAccessToken = (sumSubAccessToken) => {
  return {
    type: kycConstant.SET_SUMSUB_ACCESS_TOKEN,
    payload: {
      sumSubAccessToken,
    },
  };
};

const checkUserKYCStatus = (detail) => {
  return async (dispatch) => {
    if (detail) {
      dispatch({
        type: kycConstant.SET_KYC_STATUS,
        payload: {
          isUserKYCDone: detail.is_kyc_done,
          userKYCStatus: detail.kyc_status,
          rejection_reason: detail.rejection_reason,
        },
      });
    } else {
      dispatch({
        type: kycConstant.SET_KYC_STATUS,
        payload: {
          userKYCStatus: "",
          isUserKYCDone: false,
          rejection_reason: "",
        },
      });
    }
  };
};

export { setStartKYC, setSumSubAccessToken, checkUserKYCStatus };