export const dividendConstant = {
  SET_ENERGY_DIVIDEND: "SET_ENERGY_DIVIDEND",
  SET_NATURE_DIVIDEND: "SET_NATURE_DIVIDEND",
  SET_GREEN_H2_DIVIDEND: "SET_GREEN_H2_DIVIDEND",
  SET_BIOCHAR_DIVIDEND: "SET_BIOCHAR_DIVIDEND",
  SET_GREEN_DATA_CENTER_DIVIDEND: "SET_GREEN_DATA_CENTER_DIVIDEND",
}

const setEnergyDividend = (values) => {
  return {
    type: dividendConstant.SET_ENERGY_DIVIDEND,
    payload: values,
  };
};

const setNatureDividend = (values) => {
  return {
    type: dividendConstant.SET_NATURE_DIVIDEND,
    payload: values,
  };
};

const setGreenH2Dividend = (values) => {
  return {
    type: dividendConstant.SET_GREEN_H2_DIVIDEND,
    payload: values,
  };
};

const setBiocharDividend = (values) => {
  return {
    type: dividendConstant.SET_BIOCHAR_DIVIDEND,
    payload: values,
  };
};

const setGreenDataCenterDividend = (values) => {
  return {
    type: dividendConstant.SET_GREEN_DATA_CENTER_DIVIDEND,
    payload: values,
  };
};

export {
  setEnergyDividend,
  setNatureDividend,
  setGreenH2Dividend,
  setBiocharDividend,
  setGreenDataCenterDividend,
}