/* eslint-disable @typescript-eslint/no-explicit-any */
const PROJECT_TYPES = {
  PROJECT_TYPES_NATURE: "nature",
  PROJECT_TYPES_ENERGY: "energy",
  PROJECT_TYPES_WALLET: "wallet",
  PROJECT_TYPES_GREEN_H2: "greenH2",
  PROJECT_TYPES_GREEN_DATA_CENTER: "greenDataCenter",
  PROJECT_TYPES_BIOCHAR: "biochar",
};

const PROJECT_TYPES_URL = {
  nature: "NATURE_BASE_URL",
  energy: "ENERGY_BASE_URL",
  wallet: "WALLET_BASE_URL",
  greenH2: "GREEN_H2_BASE_URL",
  greenDataCenter: "GREEN_DATA_CENTER_BASE_URL",
  biochar: "BIOCHAR_BASE_URL",
};

export { PROJECT_TYPES, PROJECT_TYPES_URL };
