/* eslint-disable react/jsx-no-useless-fragment */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import './app.css';
import '../styles.css';
import { Fragment, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import RoutesPage from './routes';
import ThemeContainer from '../components/containers/theme';
import BootstrapToast from '../components/toast';

export function App() {
  return (
    <Fragment>
      <BootstrapToast />
      <BrowserRouter>
        <Suspense>
          <ThemeContainer>
            <RoutesPage />
          </ThemeContainer>
        </Suspense>
      </BrowserRouter>
    </Fragment>
  );
}

export default App;
