/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable multiline-comment-style */
import api_end_point from "../../../API/api-end-point";
import apiServices from "../../../API/api_service";

const getUserDetail = async (data) => {
  const url = api_end_point.USER_DETAILS;
  const res = await apiServices.post(url, data);
  return res;
};

const getLoginUserDetail = async (data) => {
  const url = api_end_point.LOGIN_USER_DETAILS;
  const res = await apiServices.post(url, data);
  return res;
};

const updateProfile = async (values) => {
  const url = api_end_point.UPDATE_PROFILE;
  const result = await apiServices.post(url, values);
  return result;
};

const getQrcode = async (values) => {
  const url = api_end_point.GET_QRCODE;
  const result = await apiServices.post(url, values);
  return result;
};

const validateQrCodeAPI = async (values) => {
  const url = api_end_point.VERIFY_QRCODE;
  const result = await apiServices.post(url, values);
  return result;
};

const refreshAWSToken = async (data) => {
  const url = api_end_point.REFRESH_AWS_TOKEN;
  const result = await apiServices.post(url, data);
  return result;
}

const logout = async (data) => {
  const url = api_end_point.LOGOUT;
  const result = await apiServices.post(url, data);
  return result;
};

export {
  getUserDetail,
  getLoginUserDetail,
  updateProfile,
  getQrcode,
  validateQrCodeAPI,
  refreshAWSToken,
  logout,
}