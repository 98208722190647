/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import userIcon from "../../../assets/icon/userIcon.svg";
import emailIcon from "../../../assets/icon/emailIcon.svg";
import mobileIcon from "../../../assets/icon/mobileIcon.svg";
import { getUserDetail } from "./services/profileService";
import useAlert from "../../hooks/use-alert";
import { updateProfile } from "./services/profileService";
import { checkLogin } from "../../API/services/checkLogin";
import { USER_ID_TYPES, ACCESS_TOKEN_TYPES } from "../../constants/localstorage-types";
import { PROJECT_TYPES } from "../../constants/project-types";
import { setUserDetail } from "./redux/profileAction";

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const { userDetail } = useSelector((state) => state.profileReducer);

  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [check2FAStatus, setCheck2FAStatus] = useState(userDetail?.mfa_status);

  const loginURL = process.env.REACT_APP_LOGIN_URL;

  const getUserData = async () => {
    const data = {
      user_id: userDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]],
      access_token: userDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]],
    };
    const res = await getUserDetail(data);
    if (res.success) {
      setData(res);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getUserData();
    setTimeout(() => {
      setCheck2FAStatus(userDetail?.mfa_status);
    }, 1000);
  }, []);

  const handleTFA = () => {
    navigate("/opt_verify");
  };

  const initialValues = {
    name: data?.name ? data.name : "",
    email: data?.email ? data.email : "",
    mobile: data?.mobile ? data.mobile : "",
  };

  const formSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required("User name must not be empty"),
    email: Yup.string()
      .trim()
      .required("User email must not be empty"),
    mobile: Yup.string()
      .trim()
      .min(9, "Mobile number minimum 9 digits")
      .max(15, "Mobile number maximum 15 digits")
      .required("User mobile must not be empty"),
  })

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      values.name = values.name.trim();
      values["user_id"] = userDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]];
      values["access_token"] = userDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]];

      const res = await updateProfile(values);
      if (res.success) {
        const updatedUserDetail = userDetail;
        updatedUserDetail.user_name = values?.name;
        dispatch(setUserDetail(updatedUserDetail));

        showAlert("SUCCESS", "Success", res.message);
      } else {
        const userId = userDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]];
        const accessToken = userDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]];
        const result = await checkLogin({ user_id: parseInt(userId), access_token: accessToken });
        if (!result) {
          dispatch(setUserDetail({}));
          window.location.href = loginURL;
        }
        showAlert("ERROR", "ERROR", res.message);
      }
      setIsLoading(false);
    },
  });

  return (
    <div className="row signup-main justify-content-center">
      <div className="col-10 col-sm-10 col-md-7 col-lg-6 col-xl-6 profile-margin my-5">
        <div className="card border-radidus-24 ps-xl-2 pe-xl-2 ps-lg-5 pe-lg-5 pb-lg-3 my-lg-5">
          <div className="card-body ps-lg-5 pe-lg-5 ps-sm-5 p-sm-5 pb-lg-3">
            <h1 className="font-30 font-w-500 font-292930  text-center">
              Profile
            </h1>
            <p className="font-16 font-w-400 font-999FAE text-center">
              Enter your detail below
            </p>
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-2 form-check form-switch">
                <label htmlFor="usernameAndEmail" className="form-label font-14 font-w-500 font-292930">
                  Check 2FA
                </label>
                <input className="form-check-input" type="checkbox" role="switch" checked={check2FAStatus} onChange={handleTFA} />
              </div>
              <div className="mb-2">
                <label htmlFor="usernameAndEmail" className="form-label font-14 font-w-500 font-292930">
                  Name
                </label>
                <div className="input-group">
                  <span className="input-group-text input-icon">
                    <img src={userIcon} alt="" />
                  </span>
                  <input type="text" className="form-control sunset-input border-radidus-8 p-lg-3 p-sm-3" {...formik.getFieldProps("name")} name="name" />
                </div>
                {formik.touched.name && formik.errors.name ? (
                  <p className="text-danger">
                    <small>{formik.errors.name}</small>
                  </p>
                ) : null}
              </div>
              <div className="mb-2">
                <label htmlFor="usernameAndEmail" className="form-label font-14 font-w-500 font-292930">
                  Email
                </label>
                <div className="input-group">
                  <span className="input-group-text input-icon">
                    <img src={emailIcon} alt="" />
                  </span>
                  <input type="text" className="form-control sunset-input border-radidus-8 p-lg-3 p-sm-3" {...formik.getFieldProps("email")} name="email" disabled />
                </div>
                {formik.touched.email && formik.errors.email && (
                  <p className="text-danger">
                    <small>{formik.errors.email}</small>
                  </p>
                )}
              </div>
              <div className="mb-2">
                <label htmlFor="usernameAndEmail" className="form-label font-14 font-w-500 font-292930">
                  Mobile
                </label>
                <div className="input-group">
                  <span className="input-group-text input-icon">
                    <img src={mobileIcon} alt="" />
                  </span>
                  <input
                    type="number"
                    className="form-control sunset-input border-radidus-8 p-lg-3 p-sm-3 no-spinner"
                    onKeyDown={(evt) => (evt.key === 'e' || evt.key === '.') && evt.preventDefault()}
                    {...formik.getFieldProps("mobile")}
                    name="mobile"
                  />
                </div>
                {formik.touched.mobile && formik.errors.mobile && (
                  <p className="text-danger">
                    <small>{formik.errors.mobile}</small>
                  </p>
                )}
              </div>
              {
                isLoading ?
                  <button className="btn loading-btn px-lg-5 py-lg-3 border-radidus-8 py-sm-3 px-sm-4" disabled>
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </button>
                  :
                  <button type="submit" className="btn btn-yellow">
                    Update
                  </button>
              }
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile