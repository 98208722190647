/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import "../../../assets/css/kyc-verification.css";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { KYC_STATUS } from "../../constants/constants";
import sunsetIcon from "../../../assets/icon/sunsetLogo.svg";
import { setStartKYC } from "./redux/kycAction";
import { checkUserKYCStatus } from "./redux/kycAction";
import { getUserKYCStatus } from "./services/kycServices";
import { USER_ID_TYPES, ACCESS_TOKEN_TYPES } from "../../constants/localstorage-types";
import { PROJECT_TYPES } from "../../constants/project-types";

const KYCVerification = () => {
  const dispatch = useDispatch();
  const { userDetail } = useSelector((state) => state.profileReducer);
  const { isUserKYCDone, userKYCStatus, rejectedType, rejectedMessage } = useSelector((state) => state.kycReducer);

  const userName = userDetail?.user_name;
  const page = "KYC";

  const startKYC = () => {
    dispatch(setStartKYC(true));
  };

  useEffect(() => {
    getKycStatus();
  }, []);

  const getKycStatus = async () => {
    const data = {
      user_id: userDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]],
      access_token: userDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]],
    };
    const userKycStatus = await getUserKYCStatus(data);
    dispatch(checkUserKYCStatus(userKycStatus));
  }


  return (
    <div className="col-sm p-1 p-md-3 min-vh-100">
      <div className="dashboard-tiles px-1 py-1 px-md-5 py-md-2 kyc-verification">
        <div className="row justify-content-end">
          <div className="col-md-4">
            <div className="kyc-heading crypto-dinar-blue font-w-600 font-24">
              Status KYC
            </div>
            <div className="kyc-status">
              {isUserKYCDone ? (
                <>
                  <span className="completed-notification"></span>
                  <span className="font-20 font-919191">Completed</span>
                </>
              ) : userKYCStatus === KYC_STATUS.INIT ? (
                <>
                  <span className="pending-notification"></span>
                  <span className="font-20 font-919191">{userKYCStatus}</span>
                </>
              ) : userKYCStatus === KYC_STATUS.REJECTED ? (
                <>
                  <span className="notstated-notification"></span>
                  <span className="font-20 font-919191">{userKYCStatus}</span>
                </>
              ) : (
                <>
                  <span className="pending-notification"></span>
                  <span className="font-20 font-919191">{userKYCStatus}</span>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="row mb-2">
          <h1 className={page === "KYC" ? "font-919191 font-gil-reg" : "crypto-dinar-blue font-gil-bold"}>
            {isUserKYCDone ? "Your KYC is completed" : "Let's complete your KYC Process"}
          </h1>
        </div>
        <div className="row mb-3 font-919191 font-gil-light">
          <h5>{`Hi ${userName}`}</h5>
        </div>

        <div className="w-100 kyc">
          <div className="kyc-form py-4 px-4">
            <div className="row text-center">
              <img src={sunsetIcon} className="kyc-img" alt="" />
            </div>
            <div className="row text-center justify-content-center mt-4">
              {isUserKYCDone ? (
                <p className="text-success">
                  <i className="fal fa-user-check"></i> &nbsp;{userKYCStatus}
                </p>
              ) :
                !userKYCStatus || userKYCStatus === KYC_STATUS.INIT || (userKYCStatus === KYC_STATUS.REJECTED && rejectedType && rejectedType === KYC_STATUS.RETRY) ? (
                  <button className="btn-warning border-0 px-4 py-3 border-radidus-8 w-20" onClick={startKYC}>
                    <i className="fal fa-user-times"></i> &nbsp;
                    {rejectedType && rejectedType === KYC_STATUS.RETRY ? "KYC Again" : "Start KYC"}
                  </button>
                ) : (
                  <p className={userKYCStatus === KYC_STATUS.REJECTED ? "font-ffffff btn-warning py-3 border-radidus-10 w-20 " : "font-ffffff btn-warning py-3 border-radidus-10 w-20"}>
                    {userKYCStatus}
                  </p>
                )}
              {
                userKYCStatus === KYC_STATUS.PENDING &&
                <p className="mt-3 font-5F738C">Note: It can take some time to get approval. Please come after some time to check your status.</p>
              }
              {
                userKYCStatus === KYC_STATUS.REJECTED && rejectedMessage &&
                <p className="mt-3 font-DC4437">{rejectedMessage}</p>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default KYCVerification