
const Footer = () => {
  return (
    <div className="footer w-100 float-start">
      <div className="copyright text-center">
        <p className="mb-0">©2023 SunsetCapital, All Rights Reserved.</p>
        {/* <a href="https://sunsetcapitdev.wpengine.com/" className="text-decoration-none">SunsetCapital</a> */}
      </div>
    </div>
  )
}

export default Footer