/* eslint-disable react-hooks/exhaustive-deps */
import { Routes, Route } from "react-router-dom";
import PrivateRoute from "../hooks/private-route";
import Dashboard from '../pages/dashboard';
import Profile from "../pages/profile";
import KYCVerification from "../pages/kyc-verification";
import Energy from "../pages/energy";
import Nature from "../pages/nature";
import Biochar from "../pages/biochar";
import GreenDataCenter from "../pages/greenDataCenter";
import Dividend from "../pages/dividend";
import Withdraw from "../pages/withdraw";
import QRCode from "../pages/qrcode";
import DividendTab from "../pages/dividend/components/dividendTab";
import GreenH2 from "../pages/greenH2";
import { PROJECT_DISABLE_STATUS } from "../constants/constants";
import ComingSoon from "../pages/comingSoon";

const RoutesPage = () => {
  return (
    <Routes>
      <Route element={<PrivateRoute />}>
        <Route path="/" element={<Dashboard />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/kyc-verification" element={<KYCVerification />} />
        <Route path="/energy" element={PROJECT_DISABLE_STATUS.ENERGY ? <ComingSoon /> : <Energy />} />
        <Route path="/nature" element={PROJECT_DISABLE_STATUS.NATURE ? <ComingSoon /> : <Nature />} />
        <Route path="/greenH2" element={PROJECT_DISABLE_STATUS.GREENH2 ? <ComingSoon /> : <GreenH2 />} />
        <Route path="/biochar" element={PROJECT_DISABLE_STATUS.BIOCHAR ? <ComingSoon /> : <Biochar />} />
        <Route path="/greenDataCenter" element={PROJECT_DISABLE_STATUS.GREENDATACENTER ? <ComingSoon /> : <GreenDataCenter />} />
        <Route path="/dividend" element={<Dividend />} />
        <Route path="/withdraw" element={<Withdraw />} />
        <Route path="/opt_verify" element={<QRCode />} />
        <Route path="/dividend/:pageType" element={<DividendTab />} />
      </Route>
    </Routes>
  )
}

export default RoutesPage;