/* eslint-disable @typescript-eslint/no-explicit-any */
const api_end_point = {
  SERVER_IMAGE_FOLDER_URL: process.env.REACT_APP_SERVER_IMAGE_FOLDER_URL,
  SERVER_ENERGY_IMAGE_FOLDER_URL: process.env.REACT_APP_SERVER_ENERGY_IMAGE_FOLDER_URL,
  NATURE_BASE_URL: process.env.REACT_APP_NATURE_BASE_URL,
  ENERGY_BASE_URL: process.env.REACT_APP_ENERGY_BASE_URL,
  WALLET_BASE_URL: process.env.REACT_APP_WALLET_BASE_URL,
  GREEN_H2_BASE_URL: process.env.REACT_APP_GREEN_H2_BASE_URL,
  GREEN_DATA_CENTER_BASE_URL: process.env.REACT_APP_GREEN_DATA_CENTER_BASE_URL,
  BIOCHAR_BASE_URL: process.env.REACT_APP_BIOCHAR_BASE_URL,

  USER_DETAILS: "/api/v1/get/user/detail",
  LOGIN_USER_DETAILS: "/api/v1/get/user/details",
  UPDATE_PROFILE: "/api/v1/user/detail/update",
  GET_QRCODE: "/api/v1/get/mfa/qrcode",
  VERIFY_QRCODE: "/api/v1/validate/mfa/code",
  SUMSUB_ACCESS_TOKEN: "/api/v1/auth/sumsub_access_token",
  KYC_STATUS: "/api/v1/kyc/status",
  INVESTMENT_HISTORY: "/api/v1/get/investment/history",
  DIVIDEND_HISTORY: "/api/v1/get/dividend/history",
  WITHDRAW_REQUEST: "/api/v1/create/withdraw/request",
  LOGOUT: "/api/v1/auth/logout",
  WITHDRAWAL_DETAIL: "/api/v1/get/withdraw/amount",
  ASSET_MASTER_DATA: "/api/v1/get/asset_master_data",
  ESTIMATED_DIVIDEND: "/api/v1/get/estimated/btc",
  REFRESH_AWS_TOKEN: "/api/v1/refresh/access/token",
  CRYPTO_PRICE: "/api/v1/get/crypto/price",
};

export default api_end_point;