/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import { getDividendList } from '../services/dividendService';
import { dividendResponseSet } from '../../../utils/token';
import Pagination from '../../../../components/layout/pagination';
import { USER_ID_TYPES, ACCESS_TOKEN_TYPES } from '../../../constants/localstorage-types';
import { PROJECT_TYPES } from '../../../constants/project-types';

const DividendList = () => {
  const { pageType } = useParams();
  const { userDetail } = useSelector((state) => state.profileReducer);

  const [dividendDetail, setDividendDetail] = useState([]);
  const [paginateData, setPaginateData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(false);

  const apiUserIdAndAccessToken = {
    energy: {
      user_id: userDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_ENERGY]],
      access_token: userDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_ENERGY]],
    },
    nature: {
      user_id: userDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]],
      access_token: userDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]],
    },
    greenH2: {
      user_id: userDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_GREEN_H2]],
      access_token: userDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_GREEN_H2]],
    },
    biochar: {
      user_id: userDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_BIOCHAR]],
      access_token: userDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_BIOCHAR]],
    },
    greenDataCenter: {
      user_id: userDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_GREEN_DATA_CENTER]],
      access_token: userDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_GREEN_DATA_CENTER]],
    },
  };

  useEffect(() => {
    getDividendDetail();
  }, []);

  const getDividendDetail = async () => {
    setIsLoading(true);
    const res = await getDividendList(apiUserIdAndAccessToken[pageType], pageType);
    if (res?.dividend_history?.length) {
      setDividendDetail(dividendResponseSet(res.dividend_history));
    } else {
      setDividendDetail([]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    setPaginateData(dividendDetail.slice(firstPageIndex, lastPageIndex));
  }, [dividendDetail, currentPage, pageSize]);

  return (
    <div style={pageType === "biochar" ? { width: "88%" } : {}}>
      <div className="page-title mb-4 mb-lg-4 px-3 px-lg-5">
        <h4 className="mb-0">Dividend</h4>
      </div>
      <div className="content px-3 px-lg-5">
        <div className="table-box">
          <div className="table-responsive">
            <table className="table table-bordered w-100 float-start">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Project Name</th>
                  {
                    pageType && pageType === "biochar" ?
                      <>
                        <th>Energy Dividend Amount</th>
                        <th>Biochar Dividend Amount</th>
                        <th>Carbon Credit Dividend Amount</th>
                        <th>Total Dividend Amount</th>
                      </>
                      :
                      <th>Dividend Amount</th>
                  }
                  <th>Transaction Hash</th>
                </tr>
              </thead>
              <tbody>
                {
                  paginateData?.length > 0 ?
                    paginateData.map((val, index) => {
                      return (
                        <tr key={index}>
                          <td>{new Date(val?.date).toLocaleDateString()}</td>
                          <td>{val?.name}</td>
                          {
                            pageType && pageType === "biochar" &&
                            <>
                              <td>${val?.energy_dividend.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 25 })}</td>
                              <td>${val?.biochar_dividend.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 25 })}</td>
                              <td>${val?.carbon_credit_dividend.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 25 })}</td>
                            </>
                          }
                          <td>${val?.dividend_amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 25 })}</td>
                          <td className='text-break'>{val?.transaction_hash}</td>
                        </tr>
                      )
                    })
                    :
                    (
                      isLoading ?
                        <tr>
                          <td className="text-center" colSpan={pageType && pageType === "biochar" ? 7 : 4}>
                            <div className="spinner-border" role="status">
                              <span className="visually-hidden">Loading...</span>
                            </div>
                          </td>
                        </tr>
                        :
                        <tr>
                          <td className="text-center" colSpan={pageType && pageType === "biochar" ? 7 : 4}>No Data Found</td>
                        </tr>
                    )
                }
              </tbody>
            </table>
          </div>
        </div>
        <Pagination currentPage={currentPage} totalCount={dividendDetail.length} pageSize={pageSize} onPageChange={(page) => setCurrentPage(page)} setPageSize={setPageSize} />
      </div>
    </div>
  )
}

export default DividendList