/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import solarEnergyIcon from "../../../assets/icon/solar-energy.svg";
import planetEarthIcon from "../../../assets/icon/planet-earth.svg";
import dataCenterIcon from "../../../assets/icon/data-center.svg";
import { getEnergyList } from "../energy/services/energyService";
import { ACCESS_TOKEN_TYPES, USER_ID_TYPES } from "../../constants/localstorage-types";
import { PROJECT_TYPES } from "../../constants/project-types";
import { getNatureList } from "../nature/services/natureService";
import { getGreenH2List } from "../greenH2/services";
import { getBiocharList } from "../biochar/services";
import { getGreenDataCenterList } from "../greenDataCenter/services";
import { PROJECT_DISABLE_STATUS } from "../../constants/constants";
import { setEnergyDividend, setNatureDividend, setGreenH2Dividend, setBiocharDividend, setGreenDataCenterDividend } from "../dividend/redux/dividendAction";

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userDetail } = useSelector((state) => state.profileReducer);

  useEffect(() => {
    getEnergyDetail();
    getNatureDetail();
    getGreenH2Detail();
    getBioCharDetail();
    getGreenDataCenterDetail();
  }, []);

  const getEnergyDetail = async () => {
    const data = {
      user_id: userDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_ENERGY]],
      access_token: userDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_ENERGY]],
    }
    const res = await getEnergyList(data);
    if (res) {
      const dividendDetail = {
        dividend: res?.dividend,
        total_invested_amount: res?.total_invested_amount
      }
      dispatch(setEnergyDividend(dividendDetail));
    }
  }

  const getNatureDetail = async () => {
    const data = {
      user_id: userDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]],
      access_token: userDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]],
    }
    const res = await getNatureList(data);
    if (res) {
      const dividendDetail = {
        dividend: res?.dividend,
        total_invested_amount: res?.total_invested_amount
      }
      dispatch(setNatureDividend(dividendDetail));
    }
  }

  const getGreenH2Detail = async () => {
    const data = {
      user_id: userDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_GREEN_H2]],
      access_token: userDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_GREEN_H2]],
    }
    const res = await getGreenH2List(data);
    if (res) {
      const dividendDetail = {
        dividend: res?.dividend,
        total_invested_amount: res?.total_invested_amount
      }
      dispatch(setGreenH2Dividend(dividendDetail));
    }
  }

  const getBioCharDetail = async () => {
    const data = {
      user_id: userDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_BIOCHAR]],
      access_token: userDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_BIOCHAR]],
    }
    const res = await getBiocharList(data);
    if (res) {
      const dividendDetail = {
        dividend: res?.dividend,
        total_invested_amount: res?.total_invested_amount
      }
      dispatch(setBiocharDividend(dividendDetail));
    }
  }

  const getGreenDataCenterDetail = async () => {
    const data = {
      user_id: userDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_GREEN_DATA_CENTER]],
      access_token: userDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_GREEN_DATA_CENTER]],
    }
    const res = await getGreenDataCenterList(data);
    if (res) {
      const dividendDetail = {
        dividend: res?.dividend,
        total_invested_amount: res?.total_invested_amount
      }
      dispatch(setGreenDataCenterDividend(dividendDetail));
    }
  }

  const handleEnergyBtn = async () => {
    const data = {
      user_id: userDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]],
      access_token: userDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]],
    }

    if (userDetail?.kyc_done) {
      window.open(`${process.env.REACT_APP_ENERGY_INVESTMENT_URL}?user_id=${data.user_id}&access_token=${data.access_token}`, "_blank", "noreferrer");
    } else {
      navigate("/kyc-verification");
    }
  }

  const handleNatureBtn = async () => {
    const data = {
      user_id: userDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]],
      access_token: userDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]],
    }

    if (userDetail?.kyc_done) {
      window.open(`${process.env.REACT_APP_NATURE_INVESTMENT_URL}?user_id=${data.user_id}&access_token=${data.access_token}`, "_blank", "noreferrer");
    } else {
      navigate("/kyc-verification");
    }
  }

  const handleGreenh2Btn = async () => {
    const data = {
      user_id: userDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]],
      access_token: userDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]],
    }

    if (userDetail?.kyc_done) {
      window.open(`${process.env.REACT_APP_GREEN_H2_INVESTMENT_URL}?user_id=${data.user_id}&access_token=${data.access_token}`, "_blank", "noreferrer");
    } else {
      navigate("/kyc-verification");
    }
  }

  const handleBiocharBtn = async () => {
    const data = {
      user_id: userDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]],
      access_token: userDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]],
    }

    if (userDetail?.kyc_done) {
      window.open(`${process.env.REACT_APP_BIOCHAR_INVESTMENT_URL}?user_id=${data.user_id}&access_token=${data.access_token}`, "_blank", "noreferrer");
    } else {
      navigate("/kyc-verification");
    }
  }

  const handleGreenDataCenterBtn = async () => {
    const data = {
      user_id: userDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]],
      access_token: userDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]],
    }

    if (userDetail?.kyc_done) {
      window.open(`${process.env.REACT_APP_GREEN_DATA_CENTER_INVESTMENT_URL}?user_id=${data.user_id}&access_token=${data.access_token}`, "_blank", "noreferrer");
    } else {
      navigate("/kyc-verification");
    }
  }

  return (
    <div className="container" style={{ marginTop: "12rem" }}>
      <div className="row">
        <div className="col-12">
          <h1 className="text-center text-white z-3 position-relative">Select Project</h1>
        </div>
      </div>

      <div className="row pt-4 pt-lg-5 d-flex justify-content-center">
        <div className={`col-12 col-md-6 col-lg-2 mb-4 ${!PROJECT_DISABLE_STATUS.ENERGY && "pointer"}`} onClick={() => !PROJECT_DISABLE_STATUS.ENERGY && handleEnergyBtn()}>
          <div className={`content-box-style text-center p-4 p-lg-4 ${PROJECT_DISABLE_STATUS.ENERGY && "energy-project-disable"}`}>
            <div className="icon-box bg-white mx-auto mb-3 mb-lg-4">
              <img src={solarEnergyIcon} className="img-fluid" alt="" />
            </div>
            <div className="text-decoration-none energy-text">
              <h4 className="text-white mb-0">
                <span>
                  Energy
                </span>
              </h4>
            </div>
          </div>
        </div>
        <div className={`col-12 col-md-6 col-lg-2 mb-4 ${!PROJECT_DISABLE_STATUS.GREENDATACENTER && "pointer"}`} onClick={() => !PROJECT_DISABLE_STATUS.GREENDATACENTER && handleGreenDataCenterBtn()}>
          <div className={`content-box-style text-center p-4 p-lg-4 ${PROJECT_DISABLE_STATUS.GREENDATACENTER && "greenDataCenter-project-disable"}`}>
            <div className="icon-box bg-white mx-auto mb-3 mb-lg-4">
              <img src={dataCenterIcon} className="img-fluid" alt="" />
            </div>
            <div className="text-decoration-none greenDataCenter-text">
              <h4 className="text-white mb-0">
                <span>Green Data Center</span>
              </h4>
            </div>
          </div>
        </div>

        <div className={`col-12 col-md-6 col-lg-2 mb-4 ${!PROJECT_DISABLE_STATUS.NATURE && "pointer"}`} onClick={() => !PROJECT_DISABLE_STATUS.NATURE && handleNatureBtn()}>
          <div className={`content-box-style text-center p-4 p-lg-4 ${PROJECT_DISABLE_STATUS.NATURE && "nature-project-disable"}`}>
            <div className="icon-box bg-white mx-auto mb-3 mb-lg-4">
              <img src={planetEarthIcon} className="img-fluid" alt="" />
            </div>
            <div className="text-decoration-none nature-text">
              <h4 className="text-white mb-0">
                <span>Nature</span>
              </h4>
            </div>
          </div>
        </div>
        <div className={`col-12 col-md-6 col-lg-2 mb-4 ${!PROJECT_DISABLE_STATUS.GREENH2 && "pointer"}`} onClick={() => !PROJECT_DISABLE_STATUS.GREENH2 && handleGreenh2Btn()}>
          <div className={`content-box-style text-center p-4 p-lg-4 ${PROJECT_DISABLE_STATUS.GREENH2 && "greenh2-project-disable"}`}>
            <div className="icon-box bg-white mx-auto mb-3 mb-lg-4">
              <img src={solarEnergyIcon} className="img-fluid" alt="" />
            </div>
            <div className="text-decoration-none greenh2-text">
              <h4 className="text-white mb-0">
                <span>Green H2</span>
              </h4>
            </div>
          </div>
        </div>
        <div className={`col-12 col-md-6 col-lg-2 mb-4 ${!PROJECT_DISABLE_STATUS.BIOCHAR && "pointer"}`} onClick={() => !PROJECT_DISABLE_STATUS.BIOCHAR && handleBiocharBtn()}>
          <div className={`content-box-style text-center p-4 p-lg-4 ${PROJECT_DISABLE_STATUS.BIOCHAR && "biochar-project-disable"}`}>
            <div className="icon-box bg-white mx-auto mb-3 mb-lg-4">
              <img src={solarEnergyIcon} className="img-fluid" alt="" />
            </div>
            <div className="text-decoration-none biochar-text">
              <h4 className="text-white mb-0">
                <span>Biochar</span>
              </h4>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Dashboard;