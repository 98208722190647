/* eslint-disable @typescript-eslint/no-explicit-any */

export const profileConstant = {
  SET_IS_LOGGED_IN: "SET_IS_LOGGED_IN",
  SET_USER_DETAIL: "SET_USER_DETAIL",
}

const setUserLoginStatus = (values) => {
  return {
    type: profileConstant.SET_IS_LOGGED_IN,
    payload: values,
  };
};

const setUserDetail = (values) => {
  return {
    type: profileConstant.SET_USER_DETAIL,
    payload: values,
  };
};

export {
  setUserLoginStatus,
  setUserDetail,
}