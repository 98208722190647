/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import "../../../assets/css/login.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Row } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { getQrcode, validateQrCodeAPI, refreshAWSToken } from "../profile/services/profileService";
import useAlert from "../../hooks/use-alert";
import { copyText } from "../../utils/copy";
import { USER_ID_TYPES, ACCESS_TOKEN_TYPES } from "../../constants/localstorage-types";
import { PROJECT_TYPES } from "../../constants/project-types";
import { setUserDetail } from "../profile/redux/profileAction";

const QRCode = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const { userDetail } = useSelector((state) => state.profileReducer);

  const [qrCode, setQrCode] = useState();
  const [code, setCode] = useState("");
  const [values, setValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [checkTFA, setCheckTFA] = useState(false);

  const handleChange = (code) => setCode(code);

  const getQRCode = async () => {
    const access_token = userDetail.aws_access_token;
    const data = {
      user_id: userDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]],
      access_token: userDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]],
    };
    const refreshToken = await refreshAWSToken(data);
    data["aws_sso_token"] = refreshToken?.aws_access_token ? refreshToken.aws_access_token : access_token;

    const res = await getQrcode(data);
    if (res.success) {
      const base64 = res.qr_code;
      const imgs = base64.split("'");
      setQrCode(imgs[1]);
      setValues((preValues) => ({
        ...preValues,
        secretKey: res.qr_secret,
      }));
    }
  };

  useEffect(() => {
    getQRCode();
    setCheckTFA(userDetail?.mfa_status)
  }, []);

  const validation = () => {
    if (code === "" || code === null || code === undefined) {
      showAlert("ERROR", "Error", "Please enter OTP");
      return false
    } else if (code.length !== 6) {
      showAlert("ERROR", "Error", "Please enter valid OTP");
      return false
    } else {
      return true;
    }
  };

  const handleSubmitVerify = async () => {
    setIsLoading(true);
    const validate = validation();
    if (validate) {
      const data = {
        user_id: userDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]],
        access_token: userDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]],
      };
      const refreshToken = await refreshAWSToken(data);
      const twoFAStatus = checkTFA ? false : true;

      const qrCodeDetail = {
        user_id: userDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]],
        access_token: userDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]],
        aws_sso_token: refreshToken?.aws_access_token ? refreshToken.aws_access_token : userDetail?.aws_access_token,
        qr_code: code,
        aws_sso_session: userDetail?.aws_session,
        user_name: userDetail?.user_name,
        multi_tsp_user_ids: userDetail?.energy_user_id,
        enabled: twoFAStatus,
      };

      const res = await validateQrCodeAPI(qrCodeDetail);
      if (res.status) {
        let updatedUserDetail = userDetail;
        updatedUserDetail["mfa_status"] = twoFAStatus;
        dispatch(setUserDetail(updatedUserDetail));
        showAlert("SUCCESS", "Success", res.message);
        navigate("/profile");
      } else {
        showAlert("ERROR", "Error", res.message);
      }
    }
    setIsLoading(false);
  };

  const handleCopyClick = () => {
    copyText(values.secretKey);
    showAlert("SUCCESS", "Success", "Secret Key Copied");
  }

  return (
    <div className="row signup-main justify-content-center">
      <div className="col-10 col-sm-10 col-md-7 col-lg-6 col-xl-6 profile-margin my-5">
        <div className="card border-radidus-24 ps-xl-2 pe-xl-2 ps-lg-5 pe-lg-5 pb-lg-3 my-lg-5">
          <div className="card-body ps-lg-5 pe-lg-5 ps-sm-5 p-sm-5 pb-lg-3">
            <h1 className="font-30 font-w-500 font-292930  text-center">
              Two Factor Authentication
            </h1>
            {
              !checkTFA ?
                <p className="font-16 font-w-400 font-999FAE text-center">
                  Please use google authentication app for 2 Factor Authentication.
                  <br />
                  Scan the Code Below
                </p> :
                <p className="font-16 font-w-400 font-999FAE text-center">
                  Please use google authentication app for disable 2 Factor Authentication.
                </p>
            }
            {qrCode && !checkTFA ? (
              <>
                <div className="text-center ">
                  <img style={{ height: "250px", width: "250px" }} src={`data:image/png;base64,${qrCode}`} alt="" />
                </div>
                <br />
                <span>Secret Key :</span> { }
                <div className="input-group">
                  <input className="form-control bg-gray" id="inputAddress" disabled value={values.secretKey} />
                  <div className="input-group-text bg-gr cursor-pointer" onClick={() => handleCopyClick()}>
                    <i className="fa fa-clone" aria-hidden="true"></i>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            <div className="my-3">
              <label htmlFor="password" className="form-label font-14 font-w-500 font-292930 mt-3">
                Enter OTP
              </label>
            </div>
            <Row className="mb-4 qrCode-input">
              <OtpInput
                value={code}
                onChange={handleChange}
                inputType="number"
                numInputs={6}
                renderInput={(props) => <input {...props} />}
              />
            </Row>

            {
              isLoading ?
                <button className="btn loading-btn px-lg-5 py-lg-3 border-radidus-8 py-sm-3 px-sm-4" disabled>
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </button>
                :
                <button onClick={handleSubmitVerify} className="btn btn-yellow">
                  Verify OTP
                </button>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default QRCode;
