/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fragment, useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getEnergyList } from "../energy/services/energyService";
import { ACCESS_TOKEN_TYPES, USER_ID_TYPES } from "../../constants/localstorage-types";
import { PROJECT_TYPES } from "../../constants/project-types";
import { getNatureList } from "../nature/services/natureService";
import { getGreenH2List } from "../greenH2/services";
import { getBiocharList } from "../biochar/services";
import { getGreenDataCenterList } from "../greenDataCenter/services";
import { getDividendList } from './services/dividendService';
import { setEnergyDividend, setNatureDividend, setGreenH2Dividend, setBiocharDividend, setGreenDataCenterDividend } from './redux/dividendAction';

const Dividend = () => {
  const dispatch = useDispatch();
  const { userDetail } = useSelector((state) => state.profileReducer);

  const [isLoading, setIsLoading] = useState(false);
  const [dividendDetail, setDividendDetail] = useState([]);
  const [totalDividend, setTotalDividend] = useState(0);
  const [totalInvestment, setTotalInvestment] = useState(0);

  useEffect(() => {
    getDividendDetail();
  }, []);

  const getDividendDetail = async () => {
    setIsLoading(true);
    await Promise.all([getEnergyDetail(), getNatureDetail(), getGreenH2Detail(), getBioCharDetail(), getGreenDataCenterDetail()]).then((response) => {
      const energy = response[0];
      if (!energy?.dividend) { energy["dividend"] = 0 };
      if (!energy?.total_invested_amount) { energy["total_invested_amount"] = 0 };

      const nature = response[1];
      if (!nature?.dividend) { nature["dividend"] = 0 };
      if (!nature?.total_invested_amount) { nature["total_invested_amount"] = 0 };

      const greenH2 = response[2];
      if (!greenH2?.dividend) { greenH2["dividend"] = 0 };
      if (!greenH2?.total_invested_amount) { greenH2["total_invested_amount"] = 0 };

      const bioChar = response[3];
      if (!bioChar?.dividend) { bioChar["dividend"] = 0 };
      if (!bioChar?.total_invested_amount) { bioChar["total_invested_amount"] = 0 };

      const greenDataCenter = response[4];
      if (!bioChar?.dividend) { bioChar["dividend"] = 0 };
      if (!bioChar?.total_invested_amount) { bioChar["total_invested_amount"] = 0 };

      const dividend = [
        { project: "Energy", slug: "energy", ...energy },
        { project: "Nature", slug: "nature", ...nature },
        // { project: "Green H2", slug: "greenH2", ...greenH2 },
        // { project: "Bio Char", slug: "biochar", ...bioChar },
        { project: "Green Data Center", slug: "greenDataCenter", ...greenDataCenter },
      ];

      setTotalDividend(dividend.reduce((partialSum, a) => partialSum + a.dividend, 0));
      setTotalInvestment(dividend.reduce((partialSum, a) => partialSum + a.total_invested_amount, 0));
      setDividendDetail(dividend)
      setIsLoading(false);
    }).catch((error) => {
      console.log(error);
    })
  }

  const getEnergyDetail = async () => {
    const data = {
      user_id: userDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_ENERGY]],
      access_token: userDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_ENERGY]],
    }
    const res = await getEnergyList(data);
    if (res) {
      const dividendDetail = {
        dividend: res?.dividend ? res?.dividend : 0,
        total_invested_amount: res?.total_invested_amount ? res?.total_invested_amount : 0
      }

      const dividendHistory = await getDividendList(data, PROJECT_TYPES.PROJECT_TYPES_ENERGY);
      if (dividendHistory?.total_dividend_amount) {
        dividendDetail["dividend"] = dividendHistory?.total_dividend_amount ? dividendHistory.total_dividend_amount : 0;
      }

      dispatch(setEnergyDividend(dividendDetail));
      return dividendDetail;
    } else {
      return {};
    }
  }

  const getNatureDetail = async () => {
    const data = {
      user_id: userDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]],
      access_token: userDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]],
    }
    const res = await getNatureList(data);
    if (res) {
      const dividendDetail = {
        dividend: res?.dividend ? res?.dividend : 0,
        total_invested_amount: res?.total_invested_amount ? res?.total_invested_amount : 0
      }
      const dividendHistory = await getDividendList(data, PROJECT_TYPES.PROJECT_TYPES_NATURE);
      if (dividendHistory?.total_dividend_amount) {
        dividendDetail["dividend"] = dividendHistory?.total_dividend_amount ? dividendHistory.total_dividend_amount : 0;
      }
      dispatch(setNatureDividend(dividendDetail));
      return dividendDetail;
    } else {
      return {};
    }
  }

  const getGreenH2Detail = async () => {
    const data = {
      user_id: userDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_GREEN_H2]],
      access_token: userDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_GREEN_H2]],
    }
    const res = await getGreenH2List(data);
    if (res) {
      const dividendDetail = {
        dividend: res?.dividend ? res?.dividend : 0,
        total_invested_amount: res?.total_invested_amount ? res?.total_invested_amount : 0
      }
      const dividendHistory = await getDividendList(data, PROJECT_TYPES.PROJECT_TYPES_GREEN_H2);
      if (dividendHistory?.total_dividend_amount) {
        dividendDetail["dividend"] = dividendHistory?.total_dividend_amount ? dividendHistory.total_dividend_amount : 0;
      }
      dispatch(setGreenH2Dividend(dividendDetail));
      return dividendDetail;
    } else {
      return {};
    }
  }

  const getBioCharDetail = async () => {
    const data = {
      user_id: userDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_BIOCHAR]],
      access_token: userDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_BIOCHAR]],
    }
    const res = await getBiocharList(data);
    if (res) {
      const dividendDetail = {
        dividend: res?.dividend ? res?.dividend : 0,
        total_invested_amount: res?.total_invested_amount ? res?.total_invested_amount : 0
      }
      const dividendHistory = await getDividendList(data, PROJECT_TYPES.PROJECT_TYPES_BIOCHAR);
      if (dividendHistory?.total_dividend_amount) {
        dividendDetail["dividend"] = dividendHistory?.total_dividend_amount ? dividendHistory.total_dividend_amount : 0;
      }
      dispatch(setBiocharDividend(dividendDetail));
      return dividendDetail;
    } else {
      return {};
    }
  }

  const getGreenDataCenterDetail = async () => {
    const data = {
      user_id: userDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_GREEN_DATA_CENTER]],
      access_token: userDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_GREEN_DATA_CENTER]],
    }
    const res = await getGreenDataCenterList(data);
    if (res) {
      const dividendDetail = {
        dividend: res?.dividend ? res?.dividend : 0,
        total_invested_amount: res?.total_invested_amount ? res?.total_invested_amount : 0
      }
      const dividendHistory = await getDividendList(data, PROJECT_TYPES.PROJECT_TYPES_GREEN_DATA_CENTER);
      if (dividendHistory?.total_dividend_amount) {
        dividendDetail["dividend"] = dividendHistory?.total_dividend_amount ? dividendHistory.total_dividend_amount : 0;
      }
      dispatch(setGreenDataCenterDividend(dividendDetail));
      return dividendDetail;
    } else {
      return {};
    }
  }

  return (
    <Fragment>
      <div className="page-title mb-4 mb-lg-4 px-3 px-lg-5">
        <h4 className="mb-0">Dividend</h4>
        {/* <button className="btn btn-primary">Withdraw</button> */}
      </div>
      <div className="content px-3 px-lg-5">

        <div className="table-box">
          <div className="table-responsive">
            <table className="table table-bordered w-100 float-start">
              <thead>
                <tr>
                  <th>Projects</th>
                  <th>Total Investment</th>
                  <th>Total Dividend</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  dividendDetail?.length > 0 ?
                    <>
                      {
                        dividendDetail.map((val, index) => {
                          return (
                            <tr key={index}>
                              <td>{val?.project}</td>
                              <td>${val?.total_invested_amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 25 })}</td>
                              <td>${val?.dividend.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 25 })}</td>
                              <td>
                                <NavLink className="nav-link font-5F738C" to={`/dividend/${val?.slug}`}>
                                  <i className="fal fa-bars font-30"></i>
                                </NavLink>
                              </td>
                            </tr>
                          )
                        })
                      }
                      <tr key={5}>
                        <td style={{ backgroundColor: "#193b3c75", color: "#fff" }}>Total</td>
                        <td style={{ backgroundColor: "#193b3c75", color: "#fff" }}>${totalInvestment.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 25 })}</td>
                        <td style={{ backgroundColor: "#193b3c75", color: "#fff" }}>${totalDividend.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 25 })}</td>
                        <td style={{ backgroundColor: "#193b3c75", color: "#fff" }}></td>
                      </tr>
                    </>
                    :
                    (
                      isLoading ?
                        <tr>
                          <td className="text-center" colSpan={4}>
                            <div className="spinner-border" role="status">
                              <span className="visually-hidden">Loading...</span>
                            </div>
                          </td>
                        </tr>
                        :
                        <tr>
                          <td className="text-center" colSpan={4}>No Data Found</td>
                        </tr>
                    )
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Fragment >
  )
}

export default Dividend