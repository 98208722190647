/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import { getMasterData, getEstimatedDividend, btcToUsd } from '../services/dividendService';
import Pagination from '../../../../components/layout/pagination';
import { USER_ID_TYPES, ACCESS_TOKEN_TYPES } from '../../../constants/localstorage-types';
import { PROJECT_TYPES } from '../../../constants/project-types';

const EstimatedDividendList = () => {
  const { pageType } = useParams();
  const { userDetail } = useSelector((state) => state.profileReducer);

  const [estimatedDividendDetail, setEstimatedDividendDetail] = useState([]);
  const [paginateData, setPaginateData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedCryptos, setSelectedCryptos] = useState();
  const [masterData, setMasterData] = useState();
  const [total, setTotal] = useState({ estimatedBTC: 0, estimatedUSD: 0 });
  const [dividendDate, setDividendDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);

  const apiUserIdAndAccessToken = {
    energy: {
      user_id: userDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_ENERGY]],
      access_token: userDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_ENERGY]],
    },
    nature: {
      user_id: userDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]],
      access_token: userDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]],
    },
    greenH2: {
      user_id: userDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_GREEN_H2]],
      access_token: userDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_GREEN_H2]],
    },
    biochar: {
      user_id: userDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_BIOCHAR]],
      access_token: userDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_BIOCHAR]],
    },
    greenDataCenter: {
      user_id: userDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_GREEN_DATA_CENTER]],
      access_token: userDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_GREEN_DATA_CENTER]],
    },
  };

  useEffect(() => {
    getAssetMasterData();
  }, []);

  useEffect(() => {
    if (selectedCryptos) {
      getEstimatedDividendData();
    }
  }, [selectedCryptos]);

  useEffect(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    setPaginateData(estimatedDividendDetail.slice(firstPageIndex, lastPageIndex));
  }, [estimatedDividendDetail, currentPage, pageSize]);

  const handleChange = (e) => {
    const num = parseInt(e.target.value, 10);
    setSelectedCryptos(num);
  };

  const getAssetMasterData = async () => {
    setIsLoading(true);
    const masterData = await getMasterData(apiUserIdAndAccessToken[pageType], pageType);
    if (masterData?.status) {
      setSelectedCryptos(masterData?.crypto_tokens[0]?.id);
      setMasterData(masterData);
    }

    setIsLoading(false);
  }

  const getEstimatedDividendData = async () => {
    setIsLoading(true);
    const estimatedDividendData = await getEstimatedDividend({ ...apiUserIdAndAccessToken[pageType], crypto_token_id: selectedCryptos }, PROJECT_TYPES.PROJECT_TYPES_GREEN_DATA_CENTER);
    const BTCPrice = await getBTCPrice();
    if (estimatedDividendData?.status) {
      const dividendDataArr = [];
      let totalBTC = 0;
      let totalUSD = 0;
      if (estimatedDividendData?.user_btc_data?.length) {
        estimatedDividendData.user_btc_data.forEach((ele, index) => {
          const obj = {
            token: ele?.token,
            date: ele?.date,
            btc: ele?.btc,
            hash_power: ele?.hash_power,
            btcPrice: BTCPrice.toFixed(2),
            estimatedEarn: parseFloat(ele?.btc * parseFloat(BTCPrice.toFixed(2)))
          }

          totalBTC = totalBTC + ele?.btc;
          totalUSD = totalUSD + obj?.estimatedEarn;
          dividendDataArr.push(obj);
        });
        getDividendDate(dividendDataArr);
      }

      setTotal({ estimatedBTC: totalBTC, estimatedUSD: totalUSD });
      setEstimatedDividendDetail(dividendDataArr);
    } else {
      setTotal({ estimatedBTC: 0, estimatedUSD: 0 });
      setEstimatedDividendDetail([]);
    }

    setIsLoading(false);
  }

  const getBTCPrice = async () => {
    const result = await btcToUsd();
    if (result?.status) {
      return parseFloat(result?.token_price);
    } else {
      return 0;
    }
  }

  const getDividendDate = async (dividendDataArr) => {
    const newArr = dividendDataArr.sort((a, b) => Number(new Date(a.date)) - Number(new Date(b.date)));
    setDividendDate(newArr[0]?.date);
  }

  return (
    <Fragment>
      <div className="page-title mb-3 mb-lg-4 px-3 px-lg-5">
        <h4 className="mb-0">Estimated Dividend</h4>
      </div>
      <div className="px-3 px-lg-5 mb-1 mt-3">
        <div className='d-flex align-items-center'>
          <span className='font-w-600' style={{ marginRight: "5px" }}>Project:</span>
          <select className="form-select mb-1 w-auto pointer" aria-label="Large select example" value={selectedCryptos} onChange={handleChange}>
            {masterData?.crypto_tokens &&
              masterData?.crypto_tokens.map((val, index) => (
                <option value={val.id} key={index}>{val.token_code}</option>
              ))
            }
          </select>
        </div>

        <div className='d-flex align-items-center'>
          <span className='font-w-600' style={{ marginRight: "5px" }}>Date:</span>
          <span>{new Date(dividendDate).toLocaleDateString()}</span>
        </div>
        <div className='d-flex'>
          <span className='font-w-600' style={{ marginRight: "5px" }}>Note:</span>
          <p>The display date is shown after last dividend calculation</p>
        </div>
      </div>

      <div className="content px-3 px-lg-5">
        <div className="table-box">
          <div className="table-responsive">
            <table className="table table-bordered w-100 float-start">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Total Token Purchased</th>
                  <th>Hash Power</th>
                  <th>Daily Estimated Mining</th>
                  <th>Daily BTC Price</th>
                  <th>Daily Estimated USD Earn</th>
                </tr>
              </thead>
              <tbody>
                {
                  paginateData?.length > 0 ?
                    paginateData.map((val, index) => {
                      return (
                        <tr key={index}>
                          <td>{new Date(val?.date).toLocaleDateString()}</td>
                          <td>{val?.token}</td>
                          <td>{val?.hash_power ? val?.hash_power : 0}</td>
                          <td>{val?.btc.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 25 })}</td>
                          <td>${val?.btcPrice.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 25 })}</td>
                          <td>${val?.estimatedEarn.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 25 })}</td>
                        </tr>
                      )
                    })
                    :
                    (
                      isLoading ?
                        <tr>
                          <td className="text-center" colSpan={6}>
                            <div className="spinner-border" role="status">
                              <span className="visually-hidden">Loading...</span>
                            </div>
                          </td>
                        </tr>
                        :
                        <tr>
                          <td className="text-center" colSpan={6}>No Data Found</td>
                        </tr>
                    )
                }
              </tbody>
            </table>
          </div>
        </div>
        <Pagination currentPage={currentPage} totalCount={estimatedDividendDetail?.length} pageSize={pageSize} onPageChange={(page) => setCurrentPage(page)} setPageSize={setPageSize} />

        <div className="row">
          <div className="col-12">
            <div className="btn-group-style">
              <span className="withdraw-btn">Total Estimated BTC: {total?.estimatedBTC.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 25 })}</span>
              <span className="withdraw-btn">Total Estimated USD: {total?.estimatedUSD.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 25 })} USD</span>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default EstimatedDividendList