/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable nonblock-statement-body-position */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SumsubWebSdk from "@sumsub/websdk-react";
import { setStartKYC } from "../redux/kycAction";
import { getKYCAccessToken } from "../services/kycServices";
import { USER_ID_TYPES, ACCESS_TOKEN_TYPES } from "../../../constants/localstorage-types";
import { PROJECT_TYPES } from "../../../constants/project-types";

const SubsubKYC = () => {
  const dispatch = useDispatch();
  const { userDetail } = useSelector((state) => state.profileReducer);

  const [isLoading, setIsLoading] = useState(true);
  const [sumSubAccessToken, setSumSubAccessToken] = useState("");

  const fetchToken = async () => {
    const data = {
      user_id: userDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]],
      access_token: userDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]],
    };
    const result = await getKYCAccessToken(data);
    if (result && result.status)
      setSumSubAccessToken(result.sumsub_access_token);
    setIsLoading(false);
  };

  const expirationHandler = () => {
    alert("Session expired, try again!");
    dispatch(setStartKYC(false));
  };

  const errorHandler = () => {
    alert("Error occured, try again!");
    dispatch(setStartKYC(false));
  };

  const messageHandler = (message) => {
    if (message === "idCheck.onApplicantSubmitted") {
      alert("Documents submitted for KYC");
      dispatch(setStartKYC(false));
    }
  };

  useEffect(() => {
    fetchToken();
  }, []);

  if (isLoading) return <h3>Loading...</h3>;

  return (
    <SumsubWebSdk
      accessToken={sumSubAccessToken}
      expirationHandler={expirationHandler}
      onMessage={messageHandler}
      onError={errorHandler}
    />
  );
};

export default SubsubKYC;
