/* eslint-disable @typescript-eslint/no-explicit-any */
const ACCESS_TOKEN_TYPES = {
    nature: "access_token",
    energy: "energy_access_token",
    greenH2: "green_h2_access_token",
    biochar: "biochar_access_token",
    greenDataCenter: "green_data_center_access_token",
    wallet: "wallet_access_token",
};

const USER_ID_TYPES = {
    nature: "user_id",
    energy: "energy_user_id",
    greenH2: "green_h2_user_id",
    biochar: "biochar_user_id",
    greenDataCenter: "green_data_center_user_id",
    wallet: "wallet_user_id",
};

export { ACCESS_TOKEN_TYPES, USER_ID_TYPES };
