const KYC_STATUS = {
  INIT: "Initial",
  REJECTED: "Rejected",
  RETRY: "RETRY",
  FINAL: "FINAL",
  PENDING: "Pending",
};

const PROJECT_DISABLE_STATUS = {
  ENERGY: false,
  NATURE: false,
  GREENH2: true,
  BIOCHAR: true,
  GREENDATACENTER: false,
};

export { KYC_STATUS, PROJECT_DISABLE_STATUS };