/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable default-param-last */
import { kycConstant } from "./kycAction";

const initialState = {
  startKYC: false,
  sumSubAccessToken: "",
  userKYCStatus: "", // kyc_status from api
  isUserKYCDone: false, // status from api
  rejectedType: "",// review_reject_type from api
  rejectedMessage: "" // review_reject_type from api
};

const kycReducer = (state = initialState, action) => {
  switch (action.type) {
    case kycConstant.SET_START_KYC: return {
      ...state,
      startKYC: action.payload.status
    };
    case kycConstant.SET_SUMSUB_ACCESS_TOKEN: return {
      ...state,
      sumSubAccessToken: action.payload.sumSubAccessToken
    };
    case kycConstant.SET_KYC_STATUS: return {
      ...state,
      userKYCStatus: action.payload.userKYCStatus,
      isUserKYCDone: action.payload.isUserKYCDone,
      rejectedType: action.payload.rejectedType,
      rejectedMessage: action.payload.rejection_reason,
    };
    default: return state;
  }
};

export default kycReducer;