import { combineReducers } from "redux";
import profileReducer from "../app/pages/profile/redux/profileReducer";
import dividendReducer from "../app/pages/dividend/redux/dividendReducer";
import hookReducer from "../app/hooks/redux/hookReducer";
import alertReducer from "../app/hooks/redux/hookReducer";
import kycReducer from "../app/pages/kyc-verification/redux/kycReducer";
import paginationReducer from "./paginationRedux/paginationReducer";

export const rootReducer = combineReducers({
  hookReducer,
  alertReducer,
  kycReducer,
  paginationReducer,
  profileReducer,
  dividendReducer,
});