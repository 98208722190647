
const getQueryParams = () => {
  const location = window.location.search;
  const params = new URLSearchParams(location)
  const user_id = parseInt(params.get('user_id'));
  const access_token = params.get('access_token');

  return { user_id, access_token };
}

const getLastTSPDetail = (data) => {
  const multi_tsp = data?.multi_tsp;
  if (multi_tsp) {
    const person = [];
    Object.keys(multi_tsp).forEach(index => {
      person.push(multi_tsp[index]);
    });
    const totalTSP = person.length - 1;
    return person[totalTSP];
  }
}

const historyResponseSet = (data) => {
  const responseArr = [];
  if (data) {

    Object.keys(data).forEach(index => {
      const response = {
        project: index,
        ...data[index]
      }
      if (response?.project && response?.purchased_token && response?.invested_amount) {
        responseArr.push(response);
      }
    });
  }

  return responseArr;
}

const dividendResponseSet = (data) => {
  const responseArr = [];
  Object.keys(data).forEach(index => {
    responseArr.push(data[index]);
  });
  return responseArr;
}

export {
  getQueryParams,
  getLastTSPDetail,
  historyResponseSet,
  dividendResponseSet,
}