import { dividendConstant } from "./dividendAction";

const initialState = {
  energy_dividend: {},
  nature_dividend: {},
  green_h2_dividend: {},
  biochar_dividend: {},
  green_data_center_dividend: {},
}


const dividendReducer = (state = initialState, action) => {
  switch (action.type) {
    case dividendConstant.SET_ENERGY_DIVIDEND:
      return {
        ...state,
        energy_dividend: action.payload,
      };
    case dividendConstant.SET_NATURE_DIVIDEND:
      return {
        ...state,
        nature_dividend: action.payload,
      };
    case dividendConstant.SET_GREEN_H2_DIVIDEND:
      return {
        ...state,
        green_h2_dividend: action.payload,
      };
    case dividendConstant.SET_BIOCHAR_DIVIDEND:
      return {
        ...state,
        biochar_dividend: action.payload,
      };
    case dividendConstant.SET_GREEN_DATA_CENTER_DIVIDEND:
      return {
        ...state,
        green_data_center_dividend: action.payload,
      };

    default:
      return state;
  }
};

export default dividendReducer
