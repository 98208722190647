/* eslint-disable @typescript-eslint/no-explicit-any */
import { getLoginUserDetail } from "../../pages/profile/services/profileService";
import { getLastTSPDetail } from "../../utils/token";
import { ACCESS_TOKEN_TYPES, USER_ID_TYPES } from "../../constants/localstorage-types";
import { PROJECT_TYPES } from "../../constants/project-types";

const checkLogin = async (data) => {
  const res = await getLoginUserDetail(data);
  if (res?.status) {
    const multi_tsp = getLastTSPDetail(res);

    let loginUserDetail = {
      user_name: res?.user_name,
      user_email: res?.user_email,
      kyc_done: res?.kyc_done,
      wallet_address: res?.tsp?.wallet_address,
      status: res?.status,
      mfa_status: res?.tsp?.mfa_status,
      aws_access_token: res?.tsp?.aws_access_token,
      aws_session: res?.tsp?.aws_session,
      isAdmin: res?.tsp?.is_admin ? res.tsp.is_admin : false
    }

    loginUserDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_ENERGY]] = multi_tsp?.user_id ? multi_tsp.user_id : null;
    loginUserDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_ENERGY]] = multi_tsp?.access_token ? multi_tsp.access_token : null;
    loginUserDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]] = res.tsp.user_id;
    loginUserDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]] = res.tsp.access_token;
    loginUserDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_GREEN_H2]] = res?.multi_tsp?.green_h2?.user_id ? res.multi_tsp.green_h2.user_id : null;
    loginUserDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_GREEN_H2]] = res?.multi_tsp?.green_h2?.access_token ? res.multi_tsp.green_h2.access_token : null;
    loginUserDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_BIOCHAR]] = res?.multi_tsp?.biochar?.user_id ? res.multi_tsp.biochar.user_id : null;
    loginUserDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_BIOCHAR]] = res?.multi_tsp?.biochar?.access_token ? res.multi_tsp.biochar.access_token : null;
    loginUserDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_GREEN_DATA_CENTER]] = res?.multi_tsp?.green_data_center?.user_id ? res.multi_tsp.green_data_center.user_id : null;
    loginUserDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_GREEN_DATA_CENTER]] = res?.multi_tsp?.green_data_center?.access_token ? res.multi_tsp.green_data_center.access_token : null;
    loginUserDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_WALLET]] = res?.multi_tsp?.wallet?.user_id ? res.multi_tsp.wallet.user_id : null;
    loginUserDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_WALLET]] = res?.multi_tsp?.wallet?.access_token ? res.multi_tsp.wallet.access_token : null;

    if (res?.multi_tsp?.wallet?.bank_detail) {
      loginUserDetail["user_bank_details"] = { ...res.multi_tsp?.wallet.bank_detail, account_holder_name: res?.user_name };
    } else {
      loginUserDetail["user_bank_details"] = { account_holder_name: res?.user_name };
    }

    return { status: true, loginUserDetail };
  } else {
    return false;
  }
}

export {
  checkLogin
}