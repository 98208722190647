/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable multiline-comment-style */
import api_end_point from "../../../API/api-end-point";
import apiServices from "../../../API/api_service";
import { PROJECT_TYPES } from "../../../constants/project-types";

const getNatureList = async (data) => {
  const url = api_end_point.INVESTMENT_HISTORY;
  const result = await apiServices.post(url, data, PROJECT_TYPES.PROJECT_TYPES_NATURE);
  return result;
};

const getWithdrawalDetails = async (data) => {
  const url = api_end_point.WITHDRAWAL_DETAIL;
  const result = await apiServices.walletPost(url, data, PROJECT_TYPES.PROJECT_TYPES_WALLET);
  return result;
};

export {
  getNatureList,
  getWithdrawalDetails
}